import axios from "axios";
import { Container, DoubleSidedImage } from "components/shared";
import React from "react";
import { Link } from "react-router-dom";

export class ErrorBoundary extends React.Component {
  state = {
    ip: '',
  };
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.discordWebhookUrl = "https://discord.com/api/webhooks/1057909478508470366/UXkcJLrQ02x9VfCDTYxTGQvsd9wg8o6D9bQuEWm77nElyVRaUYYrxZuGsqdPDOwBK79E";
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.sendMessageToDiscord(error, errorInfo);
  }

  async sendMessageToDiscord(error, errorInfo) {

    //creating function to load ip address from the API
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        this.setState({ ip: data.ip });
    });

    const user = JSON.parse(JSON.parse(localStorage.getItem('user')).auth)?.user?.user?.email;

    try {
      await axios.post(this.discordWebhookUrl, {
        content: `An error occurred in :\n${error}\n\n > Link: ${window.location.href}\n > user: ${user}\n\n\n${errorInfo}`
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <Container className="h-screen">
                <div className="h-screen flex flex-col items-center justify-center">
                    <DoubleSidedImage 
                        src="/img/others/img-2.png"
                        darkModeSrc="/img/others/img-2-dark.png"
                        alt="Something went wrong !"
                    />
                    <div className="mt-6 text-center">
                        <h3 className="mb-2">Something went wrong !</h3>
                        <p className="text-base">Something went wrong when trying execute your request</p>
                        		<Link className="h-full w-full flex items-center" to="/dashboard">
                              <span>Kembali ke dashboard</span>
                            </Link>
                    </div>
                </div>
            </Container>
        )
    }else{
        return this.props.children; 
    }

  }
}