import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import useQuery from './useQuery'

// get api services
import {apiSignIn, getDetailUser} from 'services/AuthService'

// get session slice used on baseServices.js
import {onSignInSuccess, onSignOutSuccess} from 'store/auth/sessionSlice'
import {setUser, initialState} from 'store/auth/userSlice'

// called constant and config
import appConfig from 'configs/app.config'
import {REDIRECT_URL_KEY} from 'constants/app.constant'
import {toast} from 'react-hot-toast'

function useAuth() {

    // called dispatch, navigation, query lib
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const query = useQuery()

    // set token and signedIn for global auth (used on PublicRoute.js)
    const {token, signedIn} = useSelector((state) => state.auth.session)

    const signIn = async (email, password) => {
        const Toast = toast.loading('Loading...');
        try {
            // get token for auth bearer
            const res = await apiSignIn(email, password)
            // set token to localStorage
            const token = res.data.data.token;
            localStorage.setItem('token', token);

            // set user profile
            await getDetailUser(token).then((response) => {
                // set role to local Auth
                localStorage.setItem('role', response.data.data.role.id);
                localStorage.setItem('role_name', response.data.data.role.name);
                // localStorage.setItem('sub_role', response.data.data.role.id);

                // dispatch user data, put last for performance
                const dataUser = {
                    user: response.data.data,
                    authority: [response.data.data.role.name]
                }

                dispatch(setUser(dataUser));
            });


            dispatch(onSignInSuccess(token));
            // get redirectUrl if there is one
            const redirectUrl = query.get(REDIRECT_URL_KEY)
            // navigate after success
            navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)

            // redirect if success
            const timer = setTimeout(toast.success('Berhasil login', {id: Toast}), 1500);
            clearTimeout(timer)

            // return success
            return {
                status: 'success'
            }
        } catch (errors) {
            toast.error('Terjadi kesalahan: ' + errors?.response?.data?.message || errors.toString(), {id: Toast});
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
        }
    }

    // handle sign out (no need hit api, cause there is none)
    const handleSignOut = () => {
        // dispatch signOut
        dispatch(onSignOutSuccess())
        // set user to initialState
        dispatch(setUser(initialState))

        // remove localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('role_name');
        // localStorage.removeItem('sub_role');

        navigate(appConfig.unAuthenticatedEntryPath)
    }

    // handle signOut action from others components
    const signOut = () => {
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signOut
    }
}

export default useAuth
