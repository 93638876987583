import ApiService from './ApiService'

export async function apiSignIn (email, password) {

    return ApiService.fetchData({
        url: '/auth/login-perizinan',
        method: 'post',
        data: {
            email: email,
            password: password,
            site:"IZIN"
        }
    })
}


export async function getDetailUser (token) {
    return ApiService.fetchData({
        url: '/users/me',
        method: 'get',
        data: {
            'headers': {
                'Authorization': `Bearer ${token}`
            }
        }
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}


