import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { THEME_ENUM } from 'constants/theme.constant'

const { MODE_DARK } = THEME_ENUM

const fallbackSrc = "img/others/no-img.png"

const DoubleSidedImage = ({src, darkModeSrc, alt, ...rest}) => {

	const mode = useSelector((state) => state.theme.mode)

	return (
		<img src={mode === MODE_DARK ? darkModeSrc : src} alt={alt} {...rest}  onError={(e)=>{e.target.onError = null; e.target.src = fallbackSrc}} />
	)
}

DoubleSidedImage.propTypes = {
	darkModeSrc: PropTypes.string
}

export default DoubleSidedImage