import axios from 'axios'

// import store auth global & session slice
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'

// import config, api & app constant
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant' // Use for setting token type and header auth key for each request
import { PERSIST_TOKEN_NAME } from 'constants/app.constant' // Use for save token name on local session

// this used for check if response from api contains unauthorizedCode
const unauthorizedCode = [401] //Set default from api what code they used for unauthorizedCode

// create axios services called BaseServices #check axios docs.
const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix, //HOST API
})

// interceptors for request to api
BaseService.interceptors.request.use(config => {

    if(config.url.includes("auth")){
      config.baseURL = `${appConfig.basePrefix}`
    } else{
      config.baseURL = appConfig.apiPrefix
    }

    // get local storage access token
    const accessToken = localStorage.getItem(PERSIST_TOKEN_NAME)

    if (accessToken) {
        // set headers (bearer $Token) for each request to api
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
    }

    if (config.method === "get"){
        // remove total params, cause api can't handle it
        if(config.data !== undefined && config.data !== '' && 'search' in config.data && config.data.search.length !== 0){
            delete config.data.search
        }
        if(config.data !== undefined && config.data !== '' && 'sort' in config.data){
            delete config.data.sort
        }
        if(config.data !== undefined && config.data !== '' && 'total' in config.data){
            delete config.data.total
        }
        config.params = config.data;
    }
    
    return config
}, error => {
    return Promise.reject(error)
})

// interceptors for response
BaseService.interceptors.response.use(
    response => response,
    error => {
        // check if error
        const { response } = error

        // check if return got unauthorizedCode
        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        // return error
        return Promise.reject(error)
    }
)

export default BaseService
